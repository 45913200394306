import { USER_ROLES_ENUM } from "./constants";
import { EmployeeViews, ManagerViews } from "./views";

type Routes = {
  [USER_ROLES_ENUM.MANAGER]: Record<ManagerViews, string>;
  [USER_ROLES_ENUM.EMPLOYEE]: Record<EmployeeViews, string>;
  common: Record<string, string>;
};

const ROUTES: Routes = {
  [USER_ROLES_ENUM.MANAGER]: {
    careerPath: "/admin/career-path",
    careerPathForm: "/admin/career-path-form/:employeeId",
    careerPathDetails: "/admin/career-path/details/:employeeId",
    timesheets: "/admin/timesheets",
    timesheetsDetails: "/admin/timesheets/details",
    accounting: "/admin/accounting",
    addOrEditEmployee: "/admin/add-edit-employee/:employeeId",
    employees: "/admin/employees",
    employee: "/admin/employees/:employeeId",
    projects: "/admin/projects",
    project: "/admin/project/:projectId",
    projectReport: "/admin/project/:projectId/project-report",
    assignments: "/admin/assignments",
    documents: process.env.REACT_APP_GOOGLE_DOCUMENTS_FOLDER_URL!,
    // documentSubcategory: "/documents/:subcategoryId",
    events: process.env.REACT_APP_GOOGLE_EVENTS_FOLDER_URL!,
    serviceDesk: "https://acaisoft.atlassian.net/servicedesk/customer/portals",
    processes: "/admin/processes",
  },
  [USER_ROLES_ENUM.EMPLOYEE]: {
    careerPathForm: "/career-path-form/:employeeId",
    careerPathDetails: "/career-path/details/:employeeId",
    timesheetsDetails: "/timesheets/details",
    // skillsAssessment: "/skills-assessment",
    documents: process.env.REACT_APP_GOOGLE_DOCUMENTS_FOLDER_URL!,
    events: process.env.REACT_APP_GOOGLE_EVENTS_FOLDER_URL!,
    serviceDesk: "https://acaisoft.freshservice.com/support/home",
  },
  common: {
    sessionExpired: "/session-expired",
    userNotFound: "/user-not-found",
    managerLogin: "/admin/login",
  },
};

export default ROUTES;
