import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { GET_TICKETS } from "hooks/useTickets/useTickets";

type CreateTicketData = {
  response: { id: string };
};

type CreateTicketVars = {
  title: string;
  type: string;
};

const CREATE_TICKET = gql`
  mutation CreateManagerTask($title: String!, $type: String!, $description: String) {
    response: create_manager_task(title: $title, type: $type, description: $description) {
      id
    }
  }
`;

export const useCreateTicket = (
  options?: MutationHookOptions<CreateTicketData, CreateTicketVars>
) => {
  const [createTicketMutation, data] = useMutation<
    CreateTicketData,
    CreateTicketVars
  >(CREATE_TICKET, { refetchQueries: [{ query: GET_TICKETS }], ...options });

  const createTicket = useCallback(
    async (values: CreateTicketVars) => {
      try {
        await createTicketMutation({ variables: values });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [createTicketMutation]
  );

  return { createTicket, ...data };
};
